import './UserTable.css'
import React from 'react'
import { Table, Segment, Loader, Message } from 'semantic-ui-react'
import UserPopup from './UserPopup'
import User from 'interfaces/User'
import Branch from 'interfaces/Branch'
import EditUserModal from './modals/EditUserModal'

interface PropTypes {
    users: User[],
    branches: Branch[],
    searchValue: string,
    branchFilter: number | undefined,
    isLoading: boolean,
    errorMessage: string,
    updateUser: (updatedUser: User) => void
}

function UserTable(props: PropTypes) {
    let filteredUsers = props.users
    if (props.searchValue) {
        filteredUsers = filteredUsers.filter(user =>
            user.email.toLowerCase().includes(props.searchValue.toLowerCase()))
    }
    if (props.branchFilter) {
        filteredUsers = filteredUsers.filter(user =>
            user.branchRoles.some(branchRole =>
                branchRole.branchId === props.branchFilter))
    }

    const rows = filteredUsers.map(user => {
        return (
            <Table.Row key={user.userId}>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>
                    <UserPopup user={user} branches={props.branches} />
                </Table.Cell>
                <Table.Cell>
                    <EditUserModal user={user} branches={props.branches}
                        onSuccess={updatedUser => props.updateUser(updatedUser)} />
                </Table.Cell>
            </Table.Row>
        )
    })

    return (
        <React.Fragment>
            <Segment className='user-table' data-testid='user-table' data-cy='user-table'>
                {props.isLoading === true && <>
                    <Loader active={props.isLoading} inline='centered'>Loading users</Loader>
                </>}
                {props.isLoading === false && <>
                    <Table basic='very' compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={13}>Email</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Branches</Table.HeaderCell>
                                <Table.HeaderCell width={1}>Edit</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {rows}
                        </Table.Body>
                    </Table>
                </>}
                {props.errorMessage && <>
                    <Message error data-testid='error-message' content={props.errorMessage} />
                </>}
            </Segment>
        </React.Fragment>
    )
}

export default UserTable
