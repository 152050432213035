import './App.css'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SessionContext from 'contexts/SessionContext'
import useSession from 'hooks/useSession'
import AppRoutes from 'components/AppRoutes'
import ResponseInterceptor from 'components/ResponseInterceptor'

function App() {
    const [session, setSession, isLoading] = useSession()

    if (isLoading) {
        return <></>
    }

    return (
        <BrowserRouter>
            <SessionContext.Provider value={{ session, setSession }}>
                <div className='app' data-testid='app'>
                    <ResponseInterceptor/>
                    <AppRoutes/>
                    <ToastContainer
                        position='bottom-center'
                        transition={Zoom}
                        hideProgressBar={true}
                    />
                </div>
            </SessionContext.Provider>
        </BrowserRouter>
    )
}

export default App
