import { Tab, Grid, Message, Loader } from 'semantic-ui-react'
import LicenseSectionComponent from './LicenseSection'
import LicenseSection from 'interfaces/LicenseSection'

interface PropTypes {
    licenseSections: LicenseSection[],
    errorMessage: string,
    isLoading: boolean
}

function LicenseTab(props: PropTypes) {
    let licenseSections: JSX.Element[] = []
    props.licenseSections.forEach((licenseSection, key) => {
        const isEmpty = licenseSection.licenseCategories.every(licenseCategory =>
            licenseCategory.licenses.length === 0)
        if (!isEmpty) {
            licenseSections.push((<LicenseSectionComponent key={key} name={licenseSection.name}
                licenseCategories={licenseSection.licenseCategories} />))
        }
    })

    return (
        <Tab.Pane>
            <Grid.Column width={13}>
                <Loader active={props.isLoading} inline='centered'>Loading licenses</Loader>
                {props.isLoading === false && <>
                    {licenseSections.length > 0 && <>
                        {licenseSections}
                    </>}
                    {licenseSections.length === 0 && !props.errorMessage && <>
                        <Grid.Column style={{ textAlign: 'center' }}
                            data-testid='info-message'>
                            <Message content='No licenses' />
                        </Grid.Column>
                    </>}
                    {props.errorMessage && <>
                        <Message error data-testid='error-message' content={props.errorMessage} />
                    </>}
                </>}
            </Grid.Column>
        </Tab.Pane>
    )
}

export default LicenseTab
