import auth from './auth'
import admin from './admin'
import dashboard from './dashboard'
import licenses from './licenses'
import downloads from './downloads'
import countries from './countries'
import users from './users'
import axios from 'axios'

axios.defaults.baseURL = '/api'

const api = {
    ...auth,
    ...dashboard,
    ...licenses,
    ...downloads,
    ...countries,
    ...users,
    admin
}

export default api
