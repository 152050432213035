import PortalRole from 'models/PortalRole'
import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import SessionContext from 'contexts/SessionContext'

function PrivateRoute() {
    const { session }  = useContext(SessionContext)

    if (session.portalRole === PortalRole.Unauthenticated) {
        return <Navigate to='/login'/>
    }
    else {
        return <Outlet/>
    }
}

export default PrivateRoute
