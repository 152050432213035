import './MobileMenuItem.css'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'

interface PropTypes {
    name: string
    path?: string
    icon?: string
    onClick: () => void
}

function MobileMenuItem(props: PropTypes) {
    const [isActive, setIsActive] = useState(false)
    const location = useLocation()

    useEffect(() => {
        const isActive = window.location.pathname === props.path
        setIsActive(isActive)
    }, [props.path, location.pathname])

    const isActiveClass = isActive ? 'active' : ''

    return (
        <div className={`mobile-menu-item ${isActiveClass}`} onClick={props.onClick}>
            {props.name}
            {props.icon &&
                <Icon style={{paddingLeft: '0.5em'}} size='small' name='external' />
            }
        </div>
    )
}

export default MobileMenuItem
