import { useContext } from 'react'
import { Card, Icon, Message, Table } from 'semantic-ui-react'
import SessionContext from 'contexts/SessionContext'
import AdminBranchSelect from './AdminBranchSelect'
import UserBranchSelect from './UserBranchSelect'
import PortalRole from 'models/PortalRole'
import BranchRole, { getDisplayValue } from 'models/BranchRole'
import User from 'interfaces/User'

interface PropTypes {
    user?: User
}

function ProfileCard(props: PropTypes) {
    const { session } = useContext(SessionContext)

    const branchElement = session.portalRole === PortalRole.Admin ?
        <AdminBranchSelect/> :
        <UserBranchSelect user={props.user}/>

    const roleName = session.branchRole !== BranchRole.None ? getDisplayValue(session.branchRole) : undefined

    const showBranchFeatures = (session.branchRole !== BranchRole.None || session.portalRole === PortalRole.Admin)

    return (
        <Card fluid>
            <Card.Content header='Profile'/>
            <Card.Content>
                <Table basic='very'>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Email</Table.Cell>
                            <Table.Cell>{session.email}</Table.Cell>
                        </Table.Row>
                        { showBranchFeatures && <>
                            <Table.Row>
                                <Table.Cell>Organization</Table.Cell>
                                <Table.Cell>{branchElement}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={8}>Role</Table.Cell>
                                <Table.Cell width={8}>{roleName}</Table.Cell>
                            </Table.Row>
                        </>
                        }
                    </Table.Body>
                </Table>
                {session.portalRole === PortalRole.Admin && <>
                    <Message data-cy='admin-banner'>
                        <Icon name='user secret'/>
                    You are signed in as a Kanzi Portal admin user.
                    </Message>
                </>}
            </Card.Content>
        </Card>
    )
}

export default ProfileCard
