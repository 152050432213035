/** Enum representing license types in the 'license_types' database table. */
enum LicenseType {
    Perpetual = '1001',
    Subscription = '1002',
    FloatingPerpetual = '1003',
    Trial = '1004',
    Educational = '1006',
    Dongle = '1007',
    FloatingSubscription = '1008'
}

export default LicenseType
