import { useCallback, useState } from 'react'
import { Button, Table } from 'semantic-ui-react'
import BranchField from './BranchField'
import RoleField from './RoleField'
import Branch from 'interfaces/Branch'
import BranchRole from 'models/BranchRole'
import IBranchRole from 'interfaces/BranchRole'

interface PropTypes {
    branches: Branch[],
    value: IBranchRole[],
    onChange: (branchRoles: IBranchRole[]) => void
}

function BranchSection(props: PropTypes) {
    const [branchRoles, setBranchRoles] = useState<IBranchRole[]>(props.value)

    const addBranchRole = useCallback(() => {
        const newBranchRoles = [{ branchId: -1, role: BranchRole.None }, ...branchRoles]
        setBranchRoles(newBranchRoles)
        props.onChange(newBranchRoles)
    }, [props, branchRoles])

    const removeBranchRole = useCallback((index: number) => {
        const newBranchRoles = branchRoles.filter((_, i) => i !== index)
        setBranchRoles(newBranchRoles)
        props.onChange(newBranchRoles)
    }, [props, branchRoles])

    const updateBranchRole = useCallback((index: number, branchRole: IBranchRole) => {
        const newBranchRoles = [...branchRoles]
        newBranchRoles[index] = branchRole
        setBranchRoles(newBranchRoles)
        props.onChange(newBranchRoles)
    }, [props, branchRoles])

    const branchRoleRows = branchRoles.map((branchRole, index) => {
        const selectableBranches = props.branches.filter(
            branch => branch.branchId === branchRole.branchId ||
                !branchRoles.some(branchRole => branchRole.branchId === branch.branchId))

        return (
            <Table.Row key={index}>
                <Table.Cell>
                    <BranchField hideLabel branches={selectableBranches} value={branchRole.branchId}
                        onChange={branchId => updateBranchRole(index, { ...branchRole, branchId }) } />
                </Table.Cell>
                <Table.Cell>
                    <RoleField value={branchRole.role} hideLabel
                        onChange={role => updateBranchRole(index, { ...branchRole, role }) } />
                </Table.Cell>
                <Table.Cell textAlign='center'>
                    <Button type='button' basic circular size='small' icon='minus'
                        title='Remove branch role.' onClick={() => removeBranchRole(index)} />
                </Table.Cell>
            </Table.Row>
        )
    })

    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={7}>Branch</Table.HeaderCell>
                    <Table.HeaderCell width={7}>Role</Table.HeaderCell>
                    <Table.HeaderCell width={2} textAlign='center'>
                        <Button type='button' basic circular size='small'
                            icon='plus' title='Add a branch role.'
                            onClick={() => addBranchRole()}/>
                    </Table.HeaderCell>
                </Table.Row>
                {branchRoleRows}
            </Table.Header>
            <Table.Body>
            </Table.Body>
        </Table>
    )
}

export default BranchSection
