import { useEffect } from 'react'

interface RedirectProps {
    url: string
}

const Redirect = ( props: RedirectProps ) => {
    const { url } = props
    useEffect(() => {
        window.location.href = url
    }, [url])

    return null
}

export default Redirect
