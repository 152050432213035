enum BranchRole {
    None = 'none',
    Manager = 'manager',
    AccountManager = 'account_manager',
    User = 'user'
}

export function getDisplayValue(branchRole: BranchRole) {
    switch (branchRole) {
        case BranchRole.None:
            return 'None'
        case BranchRole.Manager:
            return 'Manager'
        case BranchRole.User:
            return 'User'
        case BranchRole.AccountManager:
            return 'Account Manager'
        default:
            return ''
    }
}

export default BranchRole
