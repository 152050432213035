import './OrganizationTable.css'
import React from 'react'
import { Table, Segment, Loader, Message } from 'semantic-ui-react'
import Organization from 'interfaces/Organization'

interface PropTypes {
    organizations: Organization[],
    searchValue: string,
    isLoading: boolean,
    errorMessage: string
}

function OrganizationTable(props: PropTypes) {
    let filteredOrganizations = props.organizations
    if (props.searchValue) {
        filteredOrganizations = filteredOrganizations.filter(organization =>
            organization.name.toLowerCase().includes(props.searchValue.toLowerCase()))
    }

    const rows = filteredOrganizations.map((organization, index) => {
        return (
            <Table.Row key={index}>
                <Table.Cell>{organization.name}</Table.Cell>
            </Table.Row>
        )
    })

    return (
        <React.Fragment>
            <Segment className='organization-table' data-testid='organization-table'
                data-cy='organization-table'>
                {props.isLoading === true && <>
                    <Loader active={props.isLoading} inline='centered'>Loading organizations</Loader>
                </>}
                {props.isLoading === false && <>
                    <Table basic='very' compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {rows}
                        </Table.Body>
                    </Table>
                </>}
                {props.errorMessage && <>
                    <Message error data-testid='error-message' content={props.errorMessage} />
                </>}
            </Segment>
        </React.Fragment>
    )
}

export default OrganizationTable
