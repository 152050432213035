import { useState, useEffect } from 'react'
import { Tab, Grid } from 'semantic-ui-react'
import BranchTable from './BranchTable'
import SearchField from 'components/shared/SearchField'
import CreateBranchModal from './modals/CreateBranchModal'
import Branch from 'interfaces/Branch'
import api from 'api'

function BranchTab() {
    const [branches, setBranches] = useState<Branch[]>([])
    const [searchValue, setSearchValue] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        const loadData = async () => {
            try {
                setIsLoading(true)
                setErrorMessage('')

                const branchesResponse = await api.admin.getBranches()
                branchesResponse.data.branches.sort((a: Branch, b: Branch) =>
                    a.branchName.toLowerCase().localeCompare(b.branchName.toLowerCase()))
                setBranches(branchesResponse.data.branches)
            }
            catch (e) {
                if (e instanceof Error) {
                    setErrorMessage(`Failed to load branches: ${e.message}`)
                }
            }
            finally {
                setIsLoading(false)
            }
        }

        loadData()
    }, [])

    return (
        <Tab.Pane>
            <Grid.Column width={13}>
                <Grid verticalAlign='middle' stackable>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <SearchField onChange={setSearchValue}/>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            <CreateBranchModal
                                onSuccess={branch => setBranches([...branches, branch])} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <BranchTable
                    branches={branches}
                    searchValue={searchValue}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                />
            </Grid.Column>
        </Tab.Pane>
    )
}

export default BranchTab
