import { useEffect, useState } from 'react'
import { Tab } from 'semantic-ui-react'
import LinksTab from './links/LinksTab'
import FilesTab from './files/FilesTab'
import File from 'interfaces/File'
import api from 'api'

function DownloadView() {
    const [kanziOneFiles, setKanziOneFiles] = useState<File[]>([])
    const [kanzi3xFiles, setKanzi3xFiles] = useState<File[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        const getFiles = async () => {
            try {
                setErrorMessage('')
                setIsLoading(true)
                const response = await api.getFiles()
                const files: File[] = response.data.sort((a: File, b: File) =>
                    a.filename.localeCompare(b.filename))
                setKanziOneFiles(files.filter(file => Number(file.minor) >= 9))
                setKanzi3xFiles(files.filter(file => Number(file.minor) < 9))
            }
            catch (e) {
                if (e instanceof Error) {
                    setErrorMessage(`Failed to load files: ${e.message}`)
                }
            }
            finally {
                setIsLoading(false)
            }
        }

        getFiles()
    }, [])

    const panes = [
        {
            menuItem: { key: 'kanzi-one', content: 'Kanzi One' },
            render: () => <FilesTab isLoading={isLoading} errorMessage={errorMessage}
                files={kanziOneFiles} />
        },
        {
            menuItem: { key: 'kanzi-3x', content: 'Kanzi 3.x' },
            render: () => <FilesTab isLoading={isLoading} errorMessage={errorMessage}
                files={kanzi3xFiles} />
        },
        {
            menuItem: { key: 'links', content: 'Links' },
            render: () => <LinksTab />
        }
    ]

    return (
        <Tab panes={panes} />
    )
}

export default DownloadView
