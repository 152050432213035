import { useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import FileCategory from 'interfaces/FileCategory'
import ProductAccordion from './ProductAccordion'

interface PropTypes {
    fileCategory: FileCategory
}

function VersionAccordionItem(props: PropTypes) {
    const [isActive, setIsActive] = useState(false)

    function handleClick() {
        setIsActive(!isActive)
    }

    return  (
        <div key={props.fileCategory.name} data-cy='version-accordion-item'>
            <Accordion.Title active={isActive} onClick={handleClick}>
                <Icon name='dropdown' />
                {props.fileCategory.name}
            </Accordion.Title>
            <Accordion.Content active={isActive}>
                <ProductAccordion key={props.fileCategory.name} fileCategory={props.fileCategory} />
            </Accordion.Content>
        </div>
    )
}

export default VersionAccordionItem
