import { Dropdown, DropdownProps } from 'semantic-ui-react'
import Branch from 'interfaces/Branch'
import { useMemo } from 'react'

interface PropTypes {
    branches: Branch[],
    onChange: (filter: number) => void
}

function BranchFilter(props: PropTypes) {
    const options = useMemo(() => {
        return props.branches.map(branch => {
            return {
                key: branch.branchId,
                text: branch.branchName,
                value: branch.branchId
            }
        })
    }, [props.branches])

    function handleChange(e: unknown, data: DropdownProps) {
        props.onChange(Number(data.value))
    }

    return (
        <Dropdown
            placeholder='Select a branch'
            fluid
            clearable
            selection
            search
            closeOnChange
            options={options}
            onChange={(e, data) => handleChange(e, data)}
        />
    )
}

export default BranchFilter
