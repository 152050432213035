import axios from 'axios'
import BranchRole from 'interfaces/BranchRole'
import File from 'interfaces/File'
import User from 'interfaces/User'
import PortalRole from 'models/PortalRole'

const admin = {
    async getBranches() {
        return await axios.get('/admin/branches')
    },
    async createBranch(name: string, organizationId: number, countryId: number,
        accountManagerId: number) {
        return await axios.post('/admin/branches', {
            name: name,
            organizationId: organizationId,
            countryId: countryId,
            accountManagerId: accountManagerId
        })
    },
    async getBranchId() {
        return await axios.get('/admin/branchId')
    },
    async setBranchId(branchId: number) {
        return await axios.put('/admin/branchId', { branchId: branchId })
    },
    async getFiles() {
        return await axios.get('/admin/files')
    },
    async createDownloadLink(files: File[]) {
        return await axios.post('/admin/createDownloadLink', {
            fileMd5Hashes: files.map(file => file.md5_hash)
        })
    },
    async uploadFile(filepath: string) {
        return await axios.post(`/admin/uploadFile?filepath=${filepath}`)
    },
    async getUsers() {
        return await axios.get('/admin/users')
    },
    async createUser(user: User) {
        return await axios.post('/admin/users', {
            email: user.email,
            role: PortalRole.User,
            branchId: user.branchId,
            branchRoles: user.branchRoles
        })
    },
    async editUserBranchRoles(userId: number, branchRoles: BranchRole[]) {
        return await axios.put(`/admin/users/${userId}/branch-roles`, {
            branchRoles: branchRoles
        })
    },
    async getOrganizations() {
        return await axios.get('/admin/organizations')
    },
    async createOrganization(name: string, countryId: number) {
        return await axios.post('/admin/organizations', {
            name: name,
            countryId: countryId
        })
    },
    async getAccountManagers() {
        return await axios.get('/admin/account-managers')
    }
}

export default admin
