import { Form } from 'semantic-ui-react'

interface PropTypes {
    value: string,
    onChange: (name: string) => void
}

function NameField(props: PropTypes) {
    return (
        <Form.Input label='Name' fluid required type='text'
            value={props.value} name='name'
            onChange={e => props.onChange(e.target.value)}>
        </Form.Input>
    )
}

export default NameField
