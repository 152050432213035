import PortalRole from 'models/PortalRole'
import BranchRole from 'models/BranchRole'

class Session {
    userId: number
    portalRole: PortalRole
    branchRole: BranchRole
    email: string
    branchId?: number|undefined

    constructor(
        userId: number = -1,
        portalRole: PortalRole = PortalRole.User,
        branchRole: BranchRole = BranchRole.None,
        email: string = '',
        branchId: number | undefined = undefined
    ) {
        this.userId = userId
        this.portalRole = portalRole
        this.branchRole = branchRole
        this.email = email
        this.branchId = branchId
    }
}

export default Session
