import { Table } from 'semantic-ui-react'
import LicenseHostname from './LicenseHostname'
import License from 'interfaces/License'
import { Column } from 'interfaces/LicenseCategory'

interface PropTypes {
    license: License,
    columns: Column[]
}

// See https://stackoverflow.com/a/1353711 for details.
function isValidDate(date: Date | null) {
    return date instanceof Date && !isNaN(Number(date))
}

// Parse a date (e.g. 2022-12-30) from a date string.
function parseDate(dateString: string) {
    const date = new Date(dateString)
    if (isValidDate(date)) {
        return date.toISOString().split('T')[0]
    }
}

function LicenseTableRow(props: PropTypes) {
    let cells = []
    for (const column of props.columns) {
        let cellContent: string | JSX.Element = ''
        switch (column.indexName) {
            case 'hostname':
                cellContent = <LicenseHostname license={props.license}/>
                break
            case 'kanzi_connect_end_date':
                const kanziConnectEnabledUntil =
                props.license.kanzi_connect_end_date === 'P' ?
                    'Perpetual' : 'Not enabled'
                cellContent = kanziConnectEnabledUntil
                break
            case 'valid_end_date': {
                cellContent = parseDate(String(props.license.valid_end_date)) ?? cellContent
                break
            }
            case 'support_end_date': {
                cellContent = parseDate(String(props.license.support_end_date)) ?? cellContent
                break
            }
            case 'days_to_renew': {
                const currentDate = new Date()
                const validEndDate = new Date(String(props.license.valid_end_date))
                const supportEndDate = new Date(String(props.license.support_end_date))
                const endDate = isValidDate(validEndDate) ? validEndDate :
                    isValidDate(supportEndDate) ? supportEndDate : undefined
                if (endDate) {
                    const daysToRenew = Math.round((Number(endDate) -
                        Number(currentDate)) / (1000 * 60 * 60 * 24))
                    cellContent = daysToRenew < 0 ? 'expired' :
                        daysToRenew === 0 ? 'today' : `${daysToRenew} days`
                }
                break
            }
            default:
                cellContent = props.license[column.indexName] ?? cellContent
                break
        }

        cells.push((
            <Table.Cell width={column.width} key={column.displayName} data-testid='table-cell'>
                {cellContent}
            </Table.Cell>
        ))
    }

    return (
        <Table.Row data-testid='table-row'>
            {cells}
        </Table.Row>
    )
}

export default LicenseTableRow
