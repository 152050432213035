import React, { useState, useEffect, useContext } from 'react'
import { Dropdown, Dimmer } from 'semantic-ui-react'
import SessionContext from 'contexts/SessionContext'
import api from 'api/index'
import { toast } from 'react-toastify'
import Branch from 'interfaces/Branch'

function BranchSelect() {
    const [branches, setBranches] = useState<Branch[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const { session } = useContext(SessionContext)

    useEffect(() => {
        const getBranches = async () => {
            try {
                setIsLoading(true)
                const response = await api.admin.getBranches()
                const branches: Branch[] = response.data.branches
                branches.sort((a, b) => a.branchName.localeCompare(b.branchName))
                setBranches(branches)
            }
            catch (e) {
                if (e instanceof Error) {
                    toast.error(`Failed to get branches: ${e.message}`)
                }
            }
            finally {
                setIsLoading(false)
            }
        }

        getBranches()
    }, [])

    async function handleChange(branchId: number) {
        try {
            setIsLoading(true)
            await api.admin.setBranchId(branchId)
            // eslint-disable-next-line no-restricted-globals
            location.reload()
        }
        catch (e) {
            if (e instanceof Error) {
                toast.error(`Failed to change branch: ${e.message}`)
                setIsLoading(false)
            }
        }
    }

    const branchOptions = branches.map(branch => {
        return {
            key: branch.branchId,
            text: branch.branchName,
            value: branch.branchId
        }
    })

    return (
        <React.Fragment>
            <Dimmer.Dimmable blurring dimmed={isLoading}>
                <Dimmer inverted active={isLoading}/>
                <Dropdown
                    placeholder='Select branch'
                    fluid
                    floating
                    selection
                    options={branchOptions}
                    value={session.branchId}
                    search
                    onChange={(e, { value }) => handleChange(Number(value))}
                    data-cy='admin-branch-select'
                    style={{zIndex: 100000 }}
                />
            </Dimmer.Dimmable>
        </React.Fragment>
    )
}

export default BranchSelect
