import License from 'interfaces/License'
import axios from 'axios'
import Product from 'interfaces/Product'
import LicenseType from 'interfaces/LicenseType'

const licences = {
    async getLicenses(productId: Product, licenseTypeId: LicenseType) {
        const url = `/licenses?product_id=${productId}&license_type_id=${licenseTypeId}`
        return await axios.get(url)
    },
    async getLicenseHosts(licenses: License[]) {
        return await axios.post('/get-license-hosts', {
            licenseKeys: licenses.map(license => license.license_key)
        })
    }
}

export default licences
