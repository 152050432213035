import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { Grid, Tab } from 'semantic-ui-react'
import DownloadTab from './download/DownloadTab'
import BranchTab from './branches/BranchTab'
import UserTab from './users/UserTab'
import OrganizationTab from './organizations/OrganizationTab'
import SessionContext from 'contexts/SessionContext'
import PortalRole from 'models/PortalRole'

function AdminView() {
    const { session } = useContext(SessionContext)

    if (session.portalRole !== PortalRole.Admin) {
        return <Navigate replace to='/licenses' />
    }

    const panes = [
        {
            menuItem: { key: 'download', content: 'Download' },
            render: () => <DownloadTab/>
        },
        {
            menuItem: { key: 'organizations', content: 'Organizations' },
            render: () => <OrganizationTab/>
        },
        {
            menuItem: { key: 'branches', content: 'Branches' },
            render: () => <BranchTab/>
        },
        {
            menuItem: { key: 'users', content: 'Users' },
            render: () => <UserTab/>
        }
    ]

    return (
        <Grid.Column width={13}>
            <Tab panes={panes} />
        </Grid.Column>
    )
}

export default AdminView
