/**
 * Format bytes in a human-readable format.
 * Copy-pasted from https://stackoverflow.com/a/18650828.
 */
export function formatBytes(bytes: number, decimals: number = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

/**
 * Wait artificially to e.g. prevent a loader from finishing instantaneously.
 */
export async function waitForUxPurposes() {
    return new Promise(resolve => setTimeout(resolve, 200))
}

/** Convert a string to an enum (see https://stackoverflow.com/a/41548441). */
export function enumFromString<T>(enumType: { [s: string]: T }, stringValue: string) {
    return (Object.values(enumType) as string[]).includes(stringValue)
        ? stringValue as T
        : undefined
}
