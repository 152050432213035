import { Column } from 'interfaces/LicenseCategory'
import { Table } from 'semantic-ui-react'

interface PropTypes {
    columns: Column[]
}

function LicenseTableHeader(props: PropTypes) {
    let columns = []
    for (const column of props.columns) {
        columns.push((
            <Table.HeaderCell key={column.displayName} className='fine-print'>
                {column.displayName}
            </Table.HeaderCell>
        ))
    }

    return (
        <Table.Header>
            <Table.Row>
                {columns}
            </Table.Row>
        </Table.Header>
    )
}

export default LicenseTableHeader
