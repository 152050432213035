import { Table } from 'semantic-ui-react'
import LicenseTableRow from './LicenseTableRow'
import License from 'interfaces/License'
import { Column } from 'interfaces/LicenseCategory'

interface PropTypes {
    licenses: License[],
    columns: Column[]
}

function LicenseTableBody(props: PropTypes) {
    const sortColumn = props.columns.find(column => column.indexName === 'valid_end_date' ||
        column.indexName === 'support_end_date')

    if (sortColumn) {
        props.licenses.sort((a: License, b: License) => {
            const aDate = new Date(String(a[sortColumn.indexName]))
            const bDate = new Date(String(b[sortColumn.indexName]))
            return aDate < bDate ? 1 :
                aDate > bDate ? -1 : 0
        })
    }

    let rows = []
    for (const license of props.licenses) {
        rows.push(<LicenseTableRow key={license.license_id} license={license}
            columns={props.columns}/>)
    }

    return (
        <Table.Body>
            {rows}
        </Table.Body>
    )
}

export default LicenseTableBody
