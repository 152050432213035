import { useMemo } from 'react'
import { Form } from 'semantic-ui-react'
import Branch from 'interfaces/Branch'

interface PropTypes {
    branches: Branch[],
    value: number,
    onChange: (branchId: number) => void,
    hideLabel?: boolean
}

function BranchField(props: PropTypes) {
    const branchOptions = useMemo(() => {
        return props.branches.map(branch => {
            return { key: branch.branchId, text: branch.branchName, value: branch.branchId }
        })
    }, [props.branches])

    const label = props.hideLabel ? undefined : 'Branch'

    return (
        <Form.Dropdown placeholder='Select a branch' label={label} selection search
            lazyLoad value={props.value} fluid required options={branchOptions}
            onChange={(e, { value }) => props.onChange(Number(value))} />
    )
}

export default BranchField
