import axios from 'axios'

const dashboard = {
    async getBranch(branchId: number) {
        const url = `/branches/${branchId}`
        return await axios.get(url)
    },
    async getKanziReleases() {
        const url = '/releases'
        return await axios.get(url)
    }
}

export default dashboard
