import { useMemo } from 'react'
import { Tab, Grid, Loader, Message } from 'semantic-ui-react'
import File from 'interfaces/File'
import FileCategory from 'interfaces/FileCategory'
import VersionAccordion from './VersionAccordion'
import CreateLinkButton from 'components/admin/download/CreateLinkButton'

interface PropTypes {
    files: File[],
    isLoading: boolean,
    errorMessage: string
}

function FilesTab(props: PropTypes) {
    const versionCategories = useMemo(() => {
        const fileCategories: FileCategory[] = []
        for (const file of props.files) {
            const fileVersion = `${file.major}.${file.minor}.${file.build}`
            const fileCategory = fileCategories.find(category => category.name === fileVersion)
            if (fileCategory) {
                fileCategory.files.push(file)
            }
            else {
                fileCategories.push({ name: fileVersion, files: [file] })
            }
        }

        fileCategories.sort((a, b) => {
            const aVersion = a.name.split('.').map(x => parseInt(x))
            const bVersion = b.name.split('.').map(x => parseInt(x))
            if (aVersion[0] !== bVersion[0]) {
                return bVersion[0] - aVersion[0]
            }
            if (aVersion[1] !== bVersion[1]) {
                return bVersion[1] - aVersion[1]
            }
            return bVersion[2] - aVersion[2]
        })

        return fileCategories
    }, [props.files])

    return (
        <Tab.Pane>
            <Grid.Column width={13}>
                <Loader inline='centered' active={props.isLoading}>Loading files</Loader>
                {props.isLoading === false && <>
                    {versionCategories.length > 0 && !props.errorMessage && <>
                        <CreateLinkButton fileCategories={versionCategories} />
                        <VersionAccordion fileCategories={versionCategories} />
                    </>}
                    {versionCategories.length === 0 && !props.errorMessage && <>
                        <Grid.Column data-testid='info-message'
                            style={{ textAlign: 'center' }}>
                            <Message content='No files to download' />
                        </Grid.Column>
                    </>}
                    {props.errorMessage && <>
                        <Message error data-testid='error-message' content={props.errorMessage} />
                    </>}
                </>}
            </Grid.Column>
        </Tab.Pane>
    )
}

export default FilesTab
