import { Popup, Button, Table } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { Link } from './LinksTab'

interface PropTypes {
    link: Link
}

function LinkItem(props: PropTypes) {
    async function copyLinkToClipboard(downloadLink: string) {
        try {
            await navigator.clipboard.writeText(downloadLink)
            toast.success('Copied link to clipboard!')
        }
        catch {
            toast.error('Failed to copy to clipboard.')
        }
    }

    const timestamp = new Date(props.link.timestamp).toISOString().split('T')[0]
    const downloadLink = `https://download.rightware.com?token=${props.link.token}`
    const popupContent = props.link.files.map(file => (<p key={file.filename}>{file.filename}</p>))

    return (
        <Table.Row key={props.link.token}>
            <Table.Cell>{timestamp}</Table.Cell>
            <Table.Cell>
                <Popup content={popupContent} flowing positionFixed position='right center' trigger={
                    <Button basic size='mini' circular>
                        {props.link.files.length}
                    </Button>}
                />
            </Table.Cell>
            <Table.Cell>
                <a href={downloadLink} target='_blank' rel='noreferrer'>{downloadLink}</a>
            </Table.Cell>
            <Table.Cell>
                <Button basic icon='clipboard outline' size='mini'
                    title='Copy link to clipboard.'
                    onClick={() => copyLinkToClipboard(downloadLink)}/>
            </Table.Cell>
        </Table.Row>
    )
}

export default LinkItem
