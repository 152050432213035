import { useContext, useState } from 'react'
import SessionContext from 'contexts/SessionContext'
import { Grid, Button, Message } from 'semantic-ui-react'
import api from 'api/index'
import FileCategory from 'interfaces/FileCategory'
import PortalRole from 'models/PortalRole'
import File from 'interfaces/File'

interface SemanticMessage {
    header?: string,
    content?: string | JSX.Element
}

interface PropTypes {
    fileCategories: FileCategory[]
}

function CreateLinkButton(props: PropTypes) {
    const [okMessage, setOkMessage] = useState<SemanticMessage|undefined>()
    const [errorMessage, setErrorMessage] = useState<SemanticMessage|undefined>()
    const { session } = useContext(SessionContext)

    async function createDownloadLink(selectedFiles: File[]) {
        if(session.portalRole !== PortalRole.Admin) {
            return api.createDownloadLink(selectedFiles)
        } else {
            return api.admin.createDownloadLink(selectedFiles)
        }
    }

    async function handleClick() {
        setOkMessage(undefined)
        setErrorMessage(undefined)

        let selectedFiles = []
        for (const fileCategory of props.fileCategories) {
            for (const file of fileCategory.files) {
                if (file.isSelectedForDownload) {
                    selectedFiles.push(file)
                }
            }
        }

        if (selectedFiles.length === 0) {
            setErrorMessage({ content: 'Select one or more files to create a shareable download link.' })
            return
        }

        try {
            const response = await createDownloadLink(selectedFiles)
            const downloadLink = response.data
            setOkMessage({
                header: 'Download link created for selected files.',
                content: (
                    <a href={downloadLink} target='_blank' rel='noreferrer'>
                        {downloadLink}
                    </a>
                )
            })
        }
        catch (e) {
            if (e instanceof Error) {
                setErrorMessage({
                    header: 'Failed to create a download link.',
                    content: e.message
                })
            }
        }
    }

    return (
        <Grid.Column width={1} style={{ paddingBottom: '2em' }}>
            <p>Select one or more files to create a shareable download link</p>
            <Button onClick={handleClick} basic size='mini' color='black'
                data-cy='create-link-button'>
                Create link
            </Button>
            {okMessage && <>
                <Message positive data-testid='ok' header={okMessage.header}
                    content={okMessage.content} data-cy='ok-message'/>
            </>}
            {errorMessage && <>
                <Message negative data-testid='error' header={errorMessage.header}
                    content={errorMessage.content} />
            </>}
        </Grid.Column>
    )
}

export default CreateLinkButton
