import { useMemo, useState } from 'react'
import { Icon, Accordion, Table } from 'semantic-ui-react'
import { formatBytes } from 'utils/index'
import CreateLinkCheckbox from './CreateLinkCheckbox'
import FileMetadata from './FileMetadata'
import FileCategory from 'interfaces/FileCategory'

interface PropTypes {
    fileCategory: FileCategory
}

function FileAccordionItem(props: PropTypes) {
    const [isActive, setIsActive] = useState(false)

    function handleClick() {
        setIsActive(!isActive)
    }

    const content = useMemo(() => {
        const rows = props.fileCategory.files.map(file => (
            <Table.Row key={file.file_id}>
                <Table.Cell><CreateLinkCheckbox file={file}/></Table.Cell>
                <Table.Cell>{file.filename}</Table.Cell>
                <Table.Cell width={2}>{formatBytes(Number(file.filesize))}</Table.Cell>
                <Table.Cell><FileMetadata file={file}/></Table.Cell>
            </Table.Row>
        ))

        return (
            <Table basic='very' compact>
                <Table.Header className='fine-print'>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Filename</Table.HeaderCell>
                        <Table.HeaderCell>Filesize</Table.HeaderCell>
                        <Table.HeaderCell>Metadata</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{rows}</Table.Body>
            </Table>
        )
    }, [props.fileCategory])

    return (
        <div key={props.fileCategory.name}>
            <Accordion.Title active={isActive} onClick={handleClick}>
                <Icon name='dropdown' />
                {props.fileCategory.name}
            </Accordion.Title>
            <Accordion.Content active={isActive}>
                {content}
            </Accordion.Content>
        </div>
    )
}

export default FileAccordionItem
