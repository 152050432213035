import React, { useState, useContext } from 'react'
import { Dropdown, Dimmer } from 'semantic-ui-react'
import SessionContext from 'contexts/SessionContext'
import api from 'api/index'
import { toast } from 'react-toastify'
import User from 'interfaces/User'

interface PropTypes {
    user?: User
}

function UserBranchSelect(props: PropTypes) {
    const [isLoading, setIsLoading] = useState(false)
    const { session } = useContext(SessionContext)

    async function handleChange(branchId: number) {
        try {
            setIsLoading(true)
            await api.updateSession(branchId)
            // eslint-disable-next-line no-restricted-globals
            location.reload()
        }
        catch (e) {
            if (e instanceof Error) {
                toast.error(`Failed to change branch: ${e.message}`)
                setIsLoading(false)
            }
        }
    }

    const branchOptions = props.user?.branchRoles.map(branchRole => {
        return {
            key: branchRole.branchId,
            text: branchRole.branchName,
            value: branchRole.branchId
        }
    }) ?? []

    if (!props.user) {
        return <></>
    }

    if (props.user.branchRoles.length <= 1) {
        return (
            <span>{props?.user.branchRoles[0]?.branchName}</span>
        )
    }

    return (
        <React.Fragment>
            <Dimmer.Dimmable blurring dimmed={isLoading}>
                <Dimmer inverted active={isLoading}/>
                <Dropdown
                    placeholder='Select branch'
                    fluid
                    floating
                    selection
                    options={branchOptions}
                    value={session.branchId}
                    search
                    onChange={(e, { value }) => handleChange(Number(value))}
                    data-cy='user-branch-select'
                    style={{zIndex: 100000 }}
                />
            </Dimmer.Dimmable>
        </React.Fragment>
    )
}

export default UserBranchSelect
