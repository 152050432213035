import './LinksTab.css'
import { useState, useEffect } from 'react'
import { Tab, Grid, Loader, Message, Table } from 'semantic-ui-react'
import LinkItem from './LinkItem'
import api from 'api/index'
import File from 'interfaces/File'

export interface Link {
    timestamp: string,
    token: string,
    files: File[]
}

function LinksTab() {
    const [links, setLinks] = useState<Link[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        const getLinks = async () => {
            try {
                setErrorMessage('')
                setIsLoading(true)
                const response = await api.getLinks()
                setLinks(response.data)
            }
            catch (e) {
                if (e instanceof Error) {
                    setErrorMessage(`Failed to load links: ${e.message}`)
                }
            }
            finally {
                setIsLoading(false)
            }
        }

        getLinks()
    }, [])

    const rows = links.map(link => {
        return <LinkItem key={link.token} link={link} />
    })

    return (
        <Tab.Pane>
            <Grid.Column width={13} className='links-tab'>
                <Loader inline='centered' active={isLoading}>Loading links</Loader>
                {isLoading === false && <>
                    {links.length > 0 && <>
                        <Table basic compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Created</Table.HeaderCell>
                                    <Table.HeaderCell>Files</Table.HeaderCell>
                                    <Table.HeaderCell>Link</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {rows}
                            </Table.Body>
                        </Table>
                    </>}
                    {links.length === 0 && !errorMessage && <>
                        <Grid.Column data-testid='info-message'
                            style={{ textAlign: 'center' }}>
                            <Message content='No existing links' />
                        </Grid.Column>
                    </>}
                    {errorMessage && <>
                        <Message error data-testid='error-message' content={errorMessage} />
                    </>}
                </>}
            </Grid.Column>
        </Tab.Pane>
    )
}

export default LinksTab
