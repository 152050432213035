import LicenseTab from './LicenseTab'
import { Tab } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import LicenseCategory from 'interfaces/LicenseCategory'
import api from 'api'
import {
    allLicenseCategories,
    kanziOneLicenseCategories,
    kanziStudioLicenseCategories,
    kanziConnectLicenseCategories,
    kanziPluginLicenseCategories,
    kanziPsLicenseCategories,
} from './LicenseCategories'

interface LicenseHost {
    licenseKey: string,
    hostname: string,
    revoked: string
}

function LicenseView() {
    const [licenseCategories, setLicenseCategories] = React.useState<LicenseCategory[]>([])
    const [isEachLicenseHostLoaded, setIsEachLicenseHostLoaded] = useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)

    useEffect(() => {
        const getLicenses = async () => {
            try {
                setIsLoading(true)
                setIsEachLicenseHostLoaded(false)
                await Promise.all(allLicenseCategories.map(async licenseCategory => {
                    const response = await api.getLicenses(
                        licenseCategory.productId, licenseCategory.licenseTypeId)
                    licenseCategory.licenses = response.data
                }))
                setLicenseCategories(allLicenseCategories)
            }
            catch (e) {
                if (e instanceof Error) {
                    setErrorMessage(`Failed to load licenses: ${e.message}`)
                }
            }
            finally {
                setIsLoading(false)
            }
        }

        getLicenses()
    }, [])

    useEffect(() => {
        const getLicenseHosts = async () => {
            if (isEachLicenseHostLoaded || licenseCategories.length === 0) {
                return
            }

            try {
                await Promise.all(licenseCategories.map(async licenseCategory => {
                    const hasLicenseKeys = licenseCategory.licenses.some(license => license.license_key !== '')
                    if (!hasLicenseKeys) {
                        return
                    }

                    const response = await api.getLicenseHosts(licenseCategory.licenses)
                    const responseData: LicenseHost[] = response.data

                    licenseCategory.licenses.forEach(license => {
                        const licenseHost = responseData.find(licenseHost =>
                            license.license_key === licenseHost.licenseKey)
                        if (licenseHost) {
                            license.hostname = licenseHost.revoked === '1' ?
                                'unassigned' : licenseHost.hostname
                        }
                        else {
                            license.hostname = 'unknown'
                        }
                    })
                }))
            }
            catch {
                licenseCategories.forEach(licenseCategory => {
                    licenseCategory.licenses.forEach(license => {
                        license.hostname = 'unknown'
                    })
                })
            }
            finally {
                setIsEachLicenseHostLoaded(true)
                setLicenseCategories(licenseCategories)
            }
        }

        getLicenseHosts()
    }, [licenseCategories, isEachLicenseHostLoaded])

    const panes = [
        {
            menuItem: { key: 'kanzi-one', content: 'Kanzi One' },
            render: () => <LicenseTab licenseSections={[
                { licenseCategories: kanziOneLicenseCategories}
            ]} isLoading={isLoading} errorMessage={errorMessage} />
        },
        {
            menuItem: { key: 'kanzi-3x', content: 'Kanzi 3.x' },
            render: () => <LicenseTab licenseSections={[
                { name: 'Kanzi Studio', licenseCategories: kanziStudioLicenseCategories},
                { name: 'Kanzi Connect', licenseCategories: kanziConnectLicenseCategories},
                { name: 'Kanzi Plug-In', licenseCategories: kanziPluginLicenseCategories}
            ]} isLoading={isLoading} errorMessage={errorMessage} />
        },
        {
            menuItem: { key: 'kanzi-ps', content: 'Kanzi Platform Support' },
            render: () => <LicenseTab licenseSections={[
                { licenseCategories: kanziPsLicenseCategories}
            ]} isLoading={isLoading} errorMessage={errorMessage} />
        }
    ]

    return (
        <Tab panes={panes} />
    )
}

export default LicenseView
