import './AppHeader.css'
import { Image, Header, Grid } from 'semantic-ui-react'
import logo from 'assets/kanzi_logo.svg'
import MobileMenu from 'components/mobile-menu/MobileMenu'

function AppHeader() {
    return (
        <Grid columns='2' verticalAlign='middle'>
            <Grid.Column>
                <Image size='tiny' src={logo} />
            </Grid.Column>
            <Grid.Column textAlign='right' verticalAlign='middle'>
                <Header as='span' className='app-name'>Portal</Header>
                <MobileMenu/>
            </Grid.Column>
        </Grid>
    )
}

export default AppHeader
