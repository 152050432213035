import './MobileMenu.css'
import { Icon } from 'semantic-ui-react'
import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import MobileMenuItem from './MobileMenuItem'
import SessionContext from 'contexts/SessionContext'
import { routes, links, signOutEndpoint } from 'constants/routes'

function MobileMenu() {
    const [isVisible, setIsVisible] = useState(false)
    const { session } = useContext(SessionContext)
    const navigate = useNavigate()

    function toggleMenu() {
        setIsVisible(!isVisible)
        document.body.classList.toggle('mobile-menu-open')
    }

    function handleItemClick(path: string) {
        navigate(path)
        toggleMenu()
    }

    const toggleIconName = isVisible ? 'close' : 'bars'

    const accessibleRoutes = routes.filter(route =>
        route.roles.includes(session.portalRole) ||
        route.roles.includes(session.branchRole))

    const routeItems = accessibleRoutes.map(route => {
        return <MobileMenuItem
            key={route.name}
            name={route.name}
            path={route.path}
            onClick={() => handleItemClick(route.path)}
        />
    })

    const linkItems = links.map(link => {
        return <MobileMenuItem
            key={link.name}
            name={link.name}
            icon='external'
            onClick={() => window.open(link.url)}
        />
    })

    return (
        <>
            <Icon className='mobile-menu-toggle' name={toggleIconName} onClick={toggleMenu} />
            {isVisible === true && <>
                <div className='mobile-menu'>
                    {routeItems}
                    {linkItems}
                    <MobileMenuItem
                        onClick={() => window.location.href = signOutEndpoint}
                        name='Sign out'
                    />
                </div>
            </>}
        </>
    )
}

export default MobileMenu
