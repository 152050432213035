import { Loader } from 'semantic-ui-react'
import License from 'interfaces/License'

interface PropTypes {
    license: License
}

function LicenseHostname(props: PropTypes) {
    const isLoaderActive = !props.license.hostname ||
        props.license.hostname.length === 0

    return (
        <>
            <Loader active={isLoaderActive} inline size='mini'></Loader>
            {props.license?.hostname}
        </>
    )
}

export default LicenseHostname
