import { useState, useEffect } from 'react'
import { Tab, Grid } from 'semantic-ui-react'
import UserTable from './UserTable'
import SearchField from 'components/shared/SearchField'
import CreateUserModal from './modals/CreateUserModal'
import BranchFilter from './BranchFilter'
import Branch from 'interfaces/Branch'
import User from 'interfaces/User'
import api from 'api'

function UserTab() {
    const [users, setUsers] = useState<User[]>([])
    const [branches, setBranches] = useState<Branch[]>([])
    const [searchValue, setSearchValue] = useState('')
    const [branchFilter, setBranchFilter] = useState<number|undefined>()
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        const loadData = async () => {
            try {
                setIsLoading(true)
                setErrorMessage('')

                const branchesResponse = await api.admin.getBranches()
                branchesResponse.data.branches.sort((a: Branch, b: Branch) =>
                    a.branchName.toLowerCase().localeCompare(b.branchName.toLowerCase()))
                setBranches(branchesResponse.data.branches)

                const usersResponse = await api.admin.getUsers()
                let users: User[] = usersResponse.data.users
                users.sort((a, b) => a.email.localeCompare(b.email))
                users.forEach(user => { user.branchRoles.sort((a, b) =>
                    String(a.branchName).localeCompare(String(b.branchName))) })
                setUsers(users)
            }
            catch (e) {
                if (e instanceof Error) {
                    setErrorMessage(`Failed to load users: ${e.message}`)
                }
            }
            finally {
                setIsLoading(false)
            }
        }

        loadData()
    }, [])

    function updateUser(updatedUser: User) {
        setUsers(users.map(user =>
            user.userId === updatedUser.userId ? updatedUser : user))
    }

    return (
        <Tab.Pane>
            <Grid.Column width={13}>
                <Grid verticalAlign='middle' stackable>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <SearchField onChange={setSearchValue}/>
                        </Grid.Column>
                        <Grid.Column>
                            <BranchFilter branches={branches} onChange={setBranchFilter}/>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            <CreateUserModal branches={branches}
                                onSuccess={user => setUsers([...users, user])} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <UserTable
                    users={users}
                    branches={branches}
                    searchValue={searchValue}
                    branchFilter={branchFilter}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    updateUser={updateUser}
                />
            </Grid.Column>
        </Tab.Pane>
    )
}

export default UserTab
