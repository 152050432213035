import { useState } from 'react'
import { Loader, Message, Modal, Button, Table } from 'semantic-ui-react'
import api from 'api/index'
import File from 'interfaces/File'
import { waitForUxPurposes } from 'utils/index'

interface PropTypes {
    file: File
}

interface Metadata {
    key: string,
    value: string
}

function FileMetadata(props: PropTypes) {
    const [fileMetadata, setFileMetadata] = useState<Metadata[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [open, setOpen] = useState(false)

    async function handleClick() {
        try {
            setFileMetadata([])
            setErrorMessage('')
            setIsLoading(true)
            const response = await api.getFileMetadata(props.file.md5_hash)
            await waitForUxPurposes()
            setFileMetadata(response.data)
        }
        catch (e) {
            if (e instanceof Error) {
                setErrorMessage(`Failed to load file metadata: ${e.message}`)
            }
        }
        finally {
            setIsLoading(false)
        }
    }

    const rows = fileMetadata.map(fileMetadata =>
        <Table.Row key={fileMetadata.key}>
            <Table.Cell>{fileMetadata.key}</Table.Cell>
            <Table.Cell>{fileMetadata.value}</Table.Cell>
        </Table.Row>
    )

    return (
        <Modal
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            open={open}
            trigger={<Button onClick={handleClick} basic circular size='mini' icon='plus' />}
            size='small'
            dimmer='inverted'
        >
            <Modal.Header>File Metadata</Modal.Header>
            <Modal.Content>
                {isLoading === true && <>
                    <Loader inline='centered' active={isLoading}>Loading metadata</Loader>
                </>}
                {isLoading === false && <>
                    {rows.length > 0 && <>
                        <Table basic='very' compact>
                            <Table.Body>
                                {rows}
                            </Table.Body>
                        </Table>
                    </>}
                    {rows.length === 0 && !errorMessage && <>
                        <Message style={{textAlign: 'center'}}
                            data-testid='info-message' content='No metadata' />
                    </>}
                    {errorMessage && <>
                        <Message error data-testid='error-message' content={errorMessage} />
                    </>}
                </>}
            </Modal.Content>
        </Modal>
    )
}

export default FileMetadata
