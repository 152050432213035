import { Input } from 'semantic-ui-react'

interface PropTypes {
    onChange: (filter: string) => void
}

function SearchField(props: PropTypes) {
    return (
        <Input
            fluid
            spellCheck='false'
            onChange={e => props.onChange(e.target.value)}
            icon='search'
            placeholder='Search...'
        />
    )
}

export default SearchField
