import { useState } from 'react'
import { Modal, Button, Form } from 'semantic-ui-react'
import NameField from 'components/shared/NameField'
import CountryField from 'components/shared/CountryField'
import Organization from 'interfaces/Organization'
import api from 'api/index'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'

interface FormState {
    name: string,
    countryId: number
}

const defaultState: FormState = {
    name: '',
    countryId: -1
}

interface PropTypes {
    onSuccess: (organization: Organization) => void
}

function CreateOrganizationModal(props: PropTypes) {
    const [formState, setFormState] = useState<FormState>(defaultState)
    const [isOpen, setIsOpen] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        try {
            e.preventDefault()
            setIsSubmitting(true)

            const response = await api.admin.createOrganization(formState.name, formState.countryId)

            setFormState(defaultState)
            setIsOpen(false)
            props.onSuccess(response.data.organization)
            toast.success(`Successfully created organization with name '${formState.name}'.`)
        }
        catch (e) {
            if (e instanceof AxiosError) {
                const errorMessage = e.response?.data.error?.message ?? e.message
                toast.error(`Failed to create organization: ${errorMessage}.`)
            }
        }
        finally {
            setIsSubmitting(false)
        }
    }

    function isStateValid() {
        if (!formState.name ||
            formState.countryId === defaultState.countryId) {
            return false
        }

        return true
    }

    return (
        <Modal
            as={Form}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onSubmit={handleSubmit}
            trigger={<Button basic circular size='small' icon='plus' title='Create an organization.'/>}
            size='small'
            dimmer='inverted'
        >
            <Modal.Header>Create Organization</Modal.Header>
            <Modal.Content>
                <NameField value={formState.name}
                    onChange={name => setFormState({ ...formState, name })} />
                <CountryField value={formState.countryId}
                    onChange={countryId => setFormState({ ...formState, countryId })} />
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={isSubmitting} content='Cancel'
                    type='button' onClick={() => setIsOpen(false)}/>
                <Button disabled={isSubmitting || !isStateValid()} loading={isSubmitting}
                    type='submit' content='Ok' primary />
            </Modal.Actions>
        </Modal>
    )
}

export default CreateOrganizationModal
