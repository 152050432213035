import { useEffect, useMemo, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import Country from 'interfaces/Country'
import api from 'api'

interface PropTypes {
    value: number,
    onChange: (countryId: number) => void
}

function CountryField(props: PropTypes) {
    const [countries, setCountries] = useState<Country[]>([])

    useEffect(() => {
        const loadData = async () => {
            try {
                const response = await api.getCountries()
                const countries: Country[] = response.data.countries
                countries.sort((a, b) => a.name.localeCompare(b.name))
                setCountries(countries)
            }
            catch (e) {
                if (e instanceof AxiosError) {
                    const errorMessage = e.response?.data.error?.message ?? e.message
                    toast.error(`Failed to load countries: ${errorMessage}`)
                }
            }
        }

        loadData()
    }, [])

    const countryOptions = useMemo(() => {
        return countries.map(country => {
            return { key: country.id, text: country.name, value: country.id }
        })
    }, [countries])

    return (
        <Form.Dropdown placeholder='Select a country' label='Country' selection search
            value={props.value} fluid required options={countryOptions}
            onChange={(e, { value }) => props.onChange(Number(value))} />
    )
}

export default CountryField
