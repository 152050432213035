import './AppSidebar.css'
import { useState, useEffect, useContext } from 'react'
import { Grid, Menu } from 'semantic-ui-react'
import { useNavigate, useLocation } from 'react-router-dom'
import SessionContext from 'contexts/SessionContext'
import PortalRole from 'models/PortalRole'
import { routes, links, signOutEndpoint } from 'constants/routes'

function AppSidebar() {
    const [activeItem, setActiveItem] = useState<string | null>(null)
    const navigate = useNavigate()
    const location = useLocation()
    const { session } = useContext(SessionContext)

    useEffect(function initializeState() {
        setActiveItem(window.location.pathname)
    }, [location.pathname])

    if (session.portalRole === PortalRole.Unauthenticated) {
        return <></>
    }

    function handleClick(path: string) {
        setActiveItem(path)
        navigate(path)
    }

    const accessibleRoutes = routes.filter(route =>
        route.roles.includes(session.portalRole) ||
        route.roles.includes(session.branchRole))

    const routeItems = accessibleRoutes.map(route => {
        return <Menu.Item
            key={route.name}
            name={route.name}
            path={route.path}
            active={activeItem === route.path}
            onClick={e => handleClick(e.currentTarget.getAttribute('path') ?? '')}
            data-cy={route.path}
        />
    })

    const linkItems = links.map(link => {
        return <Menu.Item
            key={link.name}
            name={link.name}
            onClick={() => window.open(link.url)}
            icon='external'
            style={{ cursor: 'alias' }}
        />
    })

    return (
        <Grid.Column tablet={4} computer={3} className='app-sidebar'>
            <Menu fluid vertical tabular>
                {routeItems}
                {linkItems}
                <Menu.Item
                    name='sign out'
                    className='sign-out-item'
                    onClick={() => window.location.href = signOutEndpoint}
                />
            </Menu>
        </Grid.Column>
    )
}

export default AppSidebar
