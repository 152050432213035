import axios from 'axios'
import File from 'interfaces/File'

const downloads = {
    async getFiles() {
        return await axios.get('/files')
    },
    async getDownloadLink(fileMd5Hash: string) {
        return await axios.get(`/download-link?file-md5-hash=${fileMd5Hash}`)
    },
    async getFileMetadata(fileMd5Hash: string) {
        return await axios.get(`/file-metadata?file-md5-hash=${fileMd5Hash}`)
    },
    async getLinks() {
        return await axios.get('/links')
    },
    async createDownloadLink(files: File[]) {
        return await axios.post('/links', {
            fileMd5Hashes: files.map(file => file.md5_hash)
        })
    },
    async auditDownload(url: string) {
        return await axios.post(`/audit-download?url=${url}`)
    }
}

export default downloads
