import { useState, useEffect } from 'react'
import { Tab, Grid, Loader, Message } from 'semantic-ui-react'
import FileAccordion from './FileAccordion'
import CreateLinkButton from './CreateLinkButton'
import api from 'api/index'

function DownloadTab() {
    const [fileCategories, setFileCategories] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        const getFiles = async () => {
            try {
                setIsLoading(true)
                const response = await api.admin.getFiles()
                setFileCategories(response.data)
            }
            catch (e) {
                if (e instanceof Error) {
                    setErrorMessage(`Failed to load files: ${e.message}`)
                }
            }
            finally {
                setIsLoading(false)
            }
        }

        getFiles()
    }, [])

    return (
        <Tab.Pane>
            <Grid.Column width={13}>
                <Loader inline='centered' active={isLoading}>Loading files</Loader>
                {isLoading === false && <>
                    {fileCategories.length > 0 && <>
                        <CreateLinkButton fileCategories={fileCategories} />
                        <FileAccordion fileCategories={fileCategories} />
                    </>}
                    {fileCategories.length === 0 && !errorMessage && <>
                        <Grid.Column style={{ textAlign: 'center' }}
                            data-testid='info-message'>
                            <Message content={'No files to download'} />
                        </Grid.Column>
                    </>}
                    {errorMessage && <>
                        <Message error data-testid='error-message' content={errorMessage} />
                    </>}
                </>}
            </Grid.Column>
        </Tab.Pane>
    )
}

export default DownloadTab
