import { useEffect, useState, useContext } from 'react'
import { Grid, Loader } from 'semantic-ui-react'
import SessionContext from 'contexts/SessionContext'
import KanziRelease from 'interfaces/KanziRelease'
import Branch from 'interfaces/Branch'
import ProfileCard from './ProfileCard'
import WhatsNewCard from './WhatsNewCard'
import ContactCard from './ContactCard'
import api from 'api'
import User from 'interfaces/User'
import BranchRole from 'models/BranchRole'
import PortalRole from 'models/PortalRole'

function DashboardView() {
    const [releaseNotesUrl, setReleaseNotesUrl] = useState<string>('')
    const [branch, setBranch] = useState<Branch>()
    const [user, setUser] = useState<User>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { session } = useContext(SessionContext)

    useEffect(() => {
        const loadData  = async () => {
            try {
                setIsLoading(true)

                const releasesResponse = await api.getKanziReleases()
                for (const release of Object.entries<KanziRelease>(releasesResponse.data.latestLtsReleases)) {
                    setReleaseNotesUrl(release[1].releaseNotesUrl)
                    break
                }

                if (session.branchId) {
                    const branchResponse = await api.getBranch(Number(session.branchId))
                    setBranch(branchResponse.data)
                }

                const userResponse = await api.getUser(session.userId)
                const user: User = userResponse.data.user
                setUser(user)
            }
            catch (e) {
            }
            finally {
                setIsLoading(false)
            }
        }

        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showBranchFeatures = (session.branchRole !== BranchRole.None || session.portalRole === PortalRole.Admin)

    return (
        <Grid stackable columns={2}>
            {isLoading === true && <>
                <Grid.Row>
                    <Loader active={isLoading} inline='centered'>Loading dashboard</Loader>
                </Grid.Row>
            </>}
            {isLoading === false && <>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <ProfileCard user={user}/>
                    </Grid.Column>
                </Grid.Row>
                { showBranchFeatures && <>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <ContactCard branch={branch}/>
                        </Grid.Column>
                    </Grid.Row>
                </>}
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <WhatsNewCard releaseNotesUrl={releaseNotesUrl}/>
                    </Grid.Column>
                </Grid.Row>
            </>}
        </Grid>
    )
}

export default DashboardView
