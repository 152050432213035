import { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { StatusCodes } from 'http-status-codes'
import axios from 'axios'
import SessionContext from 'contexts/SessionContext'
import PortalRole from 'models/PortalRole'

function ResponseInterceptor() {
    const navigate = useNavigate()
    const { session, setSession } = useContext(SessionContext)

    useEffect(() => {
        axios.interceptors.response.use(response => {
            return response
        }, error => {
            if (error.response.status === StatusCodes.UNAUTHORIZED) {
                const loginPathname = '/login'
                if (window.location.pathname !== loginPathname) {
                    setSession({ ...session, portalRole: PortalRole.Unauthenticated })
                    navigate(loginPathname)
                }
            }

            return Promise.reject(error)
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <></>
}

export default ResponseInterceptor
