import './AppFooter.css'
import { Image, Grid } from 'semantic-ui-react'
import logo from 'assets/rightware_logo.svg'

function AppFooter() {
    const copyrightElement = <span>{`© Rightware ${new Date().getFullYear()}`}</span>
    const logoElement = <Image centered size='tiny' src={logo} />
    const thundersoftElement = <span>A ThunderSoft® Company</span>
    const policyElement = (
        <a className='privacy-link' href='https://rightware.com/privacy-policy/'>
            {'Privacy Policy'}
        </a>)

    const termsElement = (
        <a className='terms-link' href='/terms'>
            {'Terms'}
        </a>
    )

    return (
        <Grid textAlign='center' verticalAlign='middle' doubling columns='5'>
            <Grid.Column only='mobile tablet'>
                {copyrightElement}
            </Grid.Column>
            <Grid.Column only='computer' textAlign='left'>
                {copyrightElement}
            </Grid.Column>
            <Grid.Column>
                {policyElement}
            </Grid.Column>
            <Grid.Column>
                {logoElement}
            </Grid.Column>
            <Grid.Column>
                {termsElement}
            </Grid.Column>
            <Grid.Column only='mobile tablet'>
                {thundersoftElement}
            </Grid.Column>
            <Grid.Column only='computer' textAlign='right'>
                {thundersoftElement}
            </Grid.Column>
        </Grid>
    )
}

export default AppFooter
