import { Routes, Route, Navigate } from 'react-router-dom'
import PrivateRoute from 'components/PrivateRoute'
import AppLayout from 'components/AppLayout'
import Redirect from './Redirect'
import { routes, defaultPath } from 'constants/routes'

const routeElements = routes.map(route => {
    return <Route key={route.name} path={route.path} element={route.element} />
})

function AppRoutes() {
    return (
        <Routes>
            <Route path='/login' element={<Redirect url='/api/login?enable-oidc-login=true'/>} />
            <Route element={<AppLayout/>}>
                <Route path='/' element={<PrivateRoute/>}>
                    <Route index element={<Navigate to={defaultPath}/>} />
                    {routeElements}
                </Route>
                <Route path='*' element={<Navigate to={defaultPath} replace />} />
            </Route>
        </Routes>
    )
}

export default AppRoutes
