/** Enum representing product families in the database. */
enum ProductFamily {
    KanziStudio = 'kanzi_studio',
    KanziEngine = 'kanzi_engine',
    KanziConnect = 'connect',
    KanziShapes = 'plugin_shapes',
    KanziMaps = 'plugin_maps',
    KanziParticles = 'plugin_particle_system',
    KanziStereoscopy = 'plugin_stereoscopy',
    KanziVR = 'plugin_vr',
    KanziAssetPackages = 'kanzi_asset_packages',
    KanziPreviews = 'previews',
    KanziConnectPlatformPackage = 'connect_platform_package'

}

export default ProductFamily
