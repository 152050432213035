import React from 'react'
import Session from 'models/Session'

interface SessionContextType {
    session: Session
    setSession: (session: Session) => void
}

const defaultValue = { session: new Session(), setSession: () => {} }
const SessionContext = React.createContext<SessionContextType>(defaultValue)

export default SessionContext
