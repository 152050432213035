import AdminView from 'components/admin/AdminView'
import DownloadView from 'components/downloads/DownloadView'
import DashboardView from 'components/dashboard/DashboardView'
import LicenseView from 'components/licenses/LicenseView'
import BranchRole from 'models/BranchRole'
import PortalRole from 'models/PortalRole'

const routes = [
    {
        name: 'Dashboard',
        path: '/dashboard',
        element: <DashboardView/>,
        roles: [PortalRole.Admin, PortalRole.User, BranchRole.Manager, BranchRole.AccountManager]
    },
    {
        name: 'licenses',
        path: '/licenses',
        element: <LicenseView/>,
        roles: [PortalRole.Admin, BranchRole.Manager, BranchRole.AccountManager]
    },
    {
        name: 'downloads',
        path: '/downloads',
        element: <DownloadView/>,
        roles: [PortalRole.Admin, PortalRole.User, BranchRole.Manager, BranchRole.AccountManager]
    },
    {
        name: 'admin',
        path: '/admin',
        element: <AdminView/>,
        roles: [PortalRole.Admin]
    }
]

const links = [
    {
        name: 'docs',
        url: 'https://docs.kanzi.com'
    },
    {
        name: 'support',
        url: 'https://support.rightware.com'
    }
]

const defaultPath = routes[0].path
const signOutEndpoint = '/api/logout'

export { routes, links, defaultPath, signOutEndpoint }
