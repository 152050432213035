import './CookieBanner.css'
import { useEffect, useState } from 'react'
import { Button, Grid } from 'semantic-ui-react'
import Cookies from 'js-cookie'

// CcCookie = cookie consent cookie
interface CcCookie {
    revision: number
    accepted: boolean
}

// Bump this number to force users to accept cookies again.
const ccCookieRevision = 0
const ccCookieName = 'cc_cookie'

function readCcCookie() {
    try {
        const ccCookieString = String(Cookies.get(ccCookieName))
        return JSON.parse(ccCookieString) as CcCookie
    }
    catch {
        return undefined
    }
}

function CookieBanner() {
    const [isVisible, setIsVisible] = useState(false)

    function acceptCookies() {
        const ccCookie: CcCookie = { revision: ccCookieRevision, accepted: true }
        Cookies.set(ccCookieName, JSON.stringify(ccCookie), { expires: 365 })
        setIsVisible(false)
    }

    useEffect(() => {
        const ccCookie = readCcCookie()
        if (!ccCookie || ccCookie.revision !== ccCookieRevision || !ccCookie.accepted) {
            setIsVisible(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!isVisible) {
        return <></>
    }

    return (
        <div className='cookie-banner' data-cy='cookie-banner'>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                    Rightware uses cookies to ensure the proper functionality of the website.
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button primary onClick={acceptCookies} data-cy='accept-button'>
                            Accept
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default CookieBanner
