import { Popup, Button, Table } from 'semantic-ui-react'
import User from 'interfaces/User'
import Branch from 'interfaces/Branch'
import { getDisplayValue } from 'models/BranchRole'

interface PropTypes {
    user: User
    branches: Branch[]
}

function UserPopup(props: PropTypes) {
    const popupRows = props.user.branchRoles.map(branchRole => {
        const branch = props.branches.find(branch => branch.branchId === branchRole.branchId)
        const roleName = getDisplayValue(branchRole.role)

        return (
            <Table.Row key={branch?.branchId}>
                <Table.Cell>{branch?.branchName}</Table.Cell>
                <Table.Cell>{roleName}</Table.Cell>
            </Table.Row>
        )
    })

    const popupContent = popupRows.length > 0 ? (
        <div style={{ maxHeight: '30em', overflowY: 'auto' }}>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Branch</Table.HeaderCell>
                        <Table.HeaderCell>Role</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {popupRows}
                </Table.Body>
            </Table>
        </div>
    ) : (
        <p>This user does not belong to any branches.</p>
    )

    return (
        <Popup content={popupContent} flowing on='click' hideOnScroll
            positionFixed position='left center' trigger={
                <Button basic size='mini' circular>
                    {props.user.branchRoles.length}
                </Button>}
        />
    )
}

export default UserPopup
