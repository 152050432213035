import { Accordion } from 'semantic-ui-react'
import FileCategory from 'interfaces/FileCategory'
import VersionAccordionItem from './VersionAccordionItem'

interface PropTypes {
    fileCategories: FileCategory[]
}

function VersionAccordion(props: PropTypes) {
    const content = props.fileCategories.map(fileCategory => {
        return  <VersionAccordionItem key={fileCategory.name} fileCategory={fileCategory} />
    })

    return (
        <Accordion>
            {content}
        </Accordion>
    )
}

export default VersionAccordion
