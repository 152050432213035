import './BranchTable.css'
import React from 'react'
import { Table, Segment, Loader, Message } from 'semantic-ui-react'
import Branch from 'interfaces/Branch'

interface PropTypes {
    branches: Branch[],
    searchValue: string,
    isLoading: boolean,
    errorMessage: string
}

function BranchTable(props: PropTypes) {
    let filteredBranches = props.branches
    if (props.searchValue) {
        filteredBranches = filteredBranches.filter(branch =>
            branch.branchName.toLowerCase().includes(props.searchValue.toLowerCase()))
    }

    const rows = filteredBranches.map((branch, index) => {
        return (
            <Table.Row key={index}>
                <Table.Cell>{branch.branchName}</Table.Cell>
            </Table.Row>
        )
    })

    return (
        <React.Fragment>
            <Segment className='branch-table' data-testid='branch-table' data-cy='branch-table'>
                {props.isLoading === true && <>
                    <Loader active={props.isLoading} inline='centered'>Loading branches</Loader>
                </>}
                {props.isLoading === false && <>
                    <Table basic='very' compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {rows}
                        </Table.Body>
                    </Table>
                </>}
                {props.errorMessage && <>
                    <Message error data-testid='error-message' content={props.errorMessage} />
                </>}
            </Segment>
        </React.Fragment>
    )
}

export default BranchTable
