import { Outlet } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import AppSidebar from 'components/AppSidebar'
import AppHeader from './AppHeader'
import AppFooter from './AppFooter'
import CookieBanner from 'components/CookieBanner'

function AppLayout() {
    return (
        <>
            <AppHeader />
            <div className="app-content">
                <Grid>
                    <AppSidebar/>
                    <Grid.Column mobile={16} tablet={12} computer={13}>
                        <Outlet/>
                    </Grid.Column>
                </Grid>
            </div>
            <CookieBanner />
            <AppFooter />
        </>
    )
}

export default AppLayout
