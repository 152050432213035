import { useMemo } from 'react'
import { Form } from 'semantic-ui-react'
import BranchRole from 'models/BranchRole'
import { getDisplayValue } from 'models/BranchRole'

interface PropTypes {
    value: BranchRole,
    onChange: (role: BranchRole) => void,
    hideLabel?: boolean
}

function RoleField(props: PropTypes) {
    const roleOptions = useMemo(() => {
        return Object.entries(BranchRole).map(([key, value]) => {
            const text = getDisplayValue(value as BranchRole)
            return { key, text, value }
        })
            .filter(option => option.value !== BranchRole.None)
            .sort((a, b) => a.key.localeCompare(b.key))
    }, [])

    const label = props.hideLabel ? undefined : 'Role'

    return (
        <Form.Dropdown placeholder='Select a role' label={label} selection fluid
            lazyLoad required value={props.value} options={roleOptions}
            onChange={(e, { value }) => props.onChange(value as BranchRole)} />
    )
}

export default RoleField
