import './LicenseTable.css'
import React from 'react'
import { Table, Segment } from 'semantic-ui-react'
import LicenseTableHeader from './LicenseTableHeader'
import LicenseTableBody from './LicenseTableBody'
import LicenseCategory from 'interfaces/LicenseCategory'

interface PropTypes {
    licenseCategory: LicenseCategory
}

function LicenseTable(props: PropTypes) {
    return (
        <React.Fragment>
            <h3>
                {props.licenseCategory.name}
            </h3>
            <Segment className='license-table' data-testid='license-table' data-cy='license-table'>
                <Table basic='very' compact>
                    <LicenseTableHeader columns={props.licenseCategory.columns}/>
                    <LicenseTableBody columns={props.licenseCategory.columns}
                        licenses={props.licenseCategory.licenses}/>
                </Table>
            </Segment>
        </React.Fragment>
    )
}

export default LicenseTable
