import { useEffect, useMemo, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import Organization from 'interfaces/Organization'
import api from 'api'

interface PropTypes {
    value: number,
    onChange: (organizationId: number) => void
}

function OrganizationField(props: PropTypes) {
    const [organizations, setOrganizations] = useState<Organization[]>([])

    useEffect(() => {
        const loadData = async () => {
            try {
                const response = await api.admin.getOrganizations()
                const organizations: Organization[] = response.data.organizations
                organizations.sort((a, b) => a.name.localeCompare(b.name))
                setOrganizations(organizations)
            }
            catch (e) {
                if (e instanceof AxiosError) {
                    const errorMessage = e.response?.data.error?.message ?? e.message
                    toast.error(`Failed to load organizations: ${errorMessage}`)
                }
            }
        }

        loadData()
    }, [])

    const organizationOptions = useMemo(() => {
        return organizations.map(organization => {
            return { key: organization.id, text: organization.name, value: organization.id }
        })
    }, [organizations])

    return (
        <Form.Dropdown placeholder='Select an organization' label='Organization' selection search
            value={props.value} fluid required options={organizationOptions}
            onChange={(e, { value }) => props.onChange(Number(value))} />
    )
}

export default OrganizationField
