import { Accordion } from 'semantic-ui-react'
import ProductAccordionItem from './ProductAccordionItem'
import FileCategory from 'interfaces/FileCategory'
import { useMemo } from 'react'

interface PropTypes {
    fileCategory: FileCategory
}

function ProductAccordion(props: PropTypes) {
    const productCategories = useMemo(() => {
        const fileCategories: FileCategory[] = []
        for (const file of props.fileCategory.files) {
            const productFamily = String(file.product_family)
            const productCategory = fileCategories.find(category => category.name === productFamily)
            if (productCategory) {
                productCategory.files.push(file)
            }
            else {
                fileCategories.push({ name: productFamily, files: [file] })
            }
        }

        fileCategories.sort((a, b) => {
            return a.name < b.name ? -1 : 1
        })

        return fileCategories
    }, [props.fileCategory])

    const items = productCategories.map(fileCategory => {
        return <ProductAccordionItem key={fileCategory.name} fileCategory={fileCategory} />
    })

    return (
        <Accordion style={{paddingLeft: '2em', margin: '0 0 0.5em 0' }}>
            {items}
        </Accordion>
    )
}

export default ProductAccordion
