import { useEffect, useMemo, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import AccountManager from 'interfaces/AccountManager'
import api from 'api'

interface PropTypes {
    value: number,
    onChange: (accountManagerId: number) => void
}

function AccountManagerField(props: PropTypes) {
    const [accountManagers, setAccountManagers] = useState<AccountManager[]>([])

    useEffect(() => {
        const loadData = async () => {
            try {
                const response = await api.admin.getAccountManagers()
                const accountManagers: AccountManager[] = response.data.accountManagers
                accountManagers.sort((a, b) => a.name.localeCompare(b.name))
                setAccountManagers(accountManagers)
            }
            catch (e) {
                if (e instanceof AxiosError) {
                    const errorMessage = e.response?.data.error?.message ?? e.message
                    toast.error(`Failed to load account managers: ${errorMessage}`)
                }
            }
        }

        loadData()
    }, [])

    const accountManagerOptions = useMemo(() => {
        return accountManagers.map(accountManager => {
            return { key: accountManager.id, text: accountManager.name, value: accountManager.id }
        })
    }, [accountManagers])

    return (
        <Form.Dropdown placeholder='Select an account manager' label='Account Manager' selection search
            value={props.value} fluid required options={accountManagerOptions}
            onChange={(e, { value }) => props.onChange(Number(value))} />
    )
}

export default AccountManagerField
