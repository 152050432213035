import { Form } from 'semantic-ui-react'

interface PropTypes {
    value: string,
    onChange: (email: string) => void,
    disabled?: boolean
}

function EmailField(props: PropTypes) {
    return (
        <Form.Input label='Email' fluid required type='email' disabled={props.disabled}
            value={props.value} name='email' placeholder='name@host.com'
            onChange={e => props.onChange(e.target.value)}>
        </Form.Input>
    )
}

export default EmailField
