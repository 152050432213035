import LicenseCategory from 'interfaces/LicenseCategory'
import LicenseType from 'interfaces/LicenseType'
import Product from 'interfaces/Product'

export const kanziOneLicenseCategories: LicenseCategory[] = ([
    {
        name: 'Node-locked Subscription',
        productId: Product.KanziOne,
        licenseTypeId: LicenseType.Subscription,
        columns: [
            { width: 4, displayName: 'product key', indexName: 'license_key' },
            { width: 4, displayName: 'activated on (hostname)', indexName: 'hostname' },
            { width: 4, displayName: 'expires on', indexName: 'valid_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    },
    {
        name: 'Node-locked Perpetual',
        productId: Product.KanziOne,
        licenseTypeId: LicenseType.Perpetual,
        columns: [
            { width: 4, displayName: 'product key', indexName: 'license_key' },
            { width: 4, displayName: 'activated on (hostname)', indexName: 'hostname' },
            { width: 4, displayName: 'support & maintenance expires on', indexName: 'support_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    },
    {
        name: 'Floating Subscription',
        productId: Product.KanziOne,
        licenseTypeId: LicenseType.FloatingSubscription,
        columns: [
            { width: 4, displayName: 'license server', indexName: 'license_file_hwid' },
            { width: 4, displayName: 'options', indexName: 'floating_type' },
            { width: 2, displayName: 'user count', indexName: 'user_count' },
            { width: 4, displayName: 'expires on', indexName: 'support_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    },
    {
        name: 'Floating Perpetual',
        productId: Product.KanziOne,
        licenseTypeId: LicenseType.FloatingPerpetual,
        columns: [
            { width: 4, displayName: 'license server', indexName: 'license_file_hwid' },
            { width: 4, displayName: 'options', indexName: 'floating_type' },
            { width: 2, displayName: 'user count', indexName: 'user_count' },
            { width: 4, displayName: 'support & maintenance expires on', indexName: 'support_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    },
    {
        name: 'Dongle Perpetual',
        productId: Product.KanziOne,
        licenseTypeId: LicenseType.Dongle,
        columns: [
            { width: 8, displayName: 'usb dongle id', indexName: 'license_file_hwid' },
            { width: 4, displayName: 'support & maintenance expires on', indexName: 'support_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    }
])

export const kanziStudioLicenseCategories: LicenseCategory[] = ([
    {
        name: 'Node-locked Subscription',
        productId: Product.KanziStudio,
        licenseTypeId: LicenseType.Subscription,
        columns: [
            { width: 4, displayName: 'product key', indexName: 'license_key' },
            { width: 4, displayName: 'activated on (hostname)', indexName: 'hostname' },
            { width: 4, displayName: 'expires on', indexName: 'valid_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    },
    {
        name: 'Node-locked Perpetual',
        productId: Product.KanziStudio,
        licenseTypeId: LicenseType.Perpetual,
        columns: [
            { width: 4, displayName: 'product key', indexName: 'license_key' },
            { width: 4, displayName: 'activated on (hostname)', indexName: 'hostname' },
            { width: 4, displayName: 'support & maintenance expires on', indexName: 'support_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    },
    {
        name: 'Floating Perpetual',
        productId: Product.KanziStudio,
        licenseTypeId: LicenseType.FloatingPerpetual,
        columns: [
            { width: 4, displayName: 'license server', indexName: 'license_file_hwid' },
            { width: 4, displayName: 'options', indexName: 'floating_type' },
            { width: 2, displayName: 'user count', indexName: 'user_count' },
            { width: 4, displayName: 'support & maintenance expires on', indexName: 'support_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    },
    {
        name: 'Dongle Perpetual',
        productId: Product.KanziStudio,
        licenseTypeId: LicenseType.Dongle,
        columns: [
            { width: 8, displayName: 'usb dongle id', indexName: 'license_file_hwid' },
            { width: 4, displayName: 'support & maintenance expires on', indexName: 'support_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    }
])

export const kanziConnectLicenseCategories: LicenseCategory[] = ([
    {
        name: 'Node-locked Subscription',
        productId: Product.KanziConnect,
        licenseTypeId: LicenseType.Subscription,
        columns: [
            { width: 8, displayName: 'tied to kanzi studio license', indexName: 'kc_tied_to' },
            { width: 4, displayName: 'expires on', indexName: 'support_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    },
    {
        name: 'Node-locked Perpetual',
        productId: Product.KanziConnect,
        licenseTypeId: LicenseType.Perpetual,
        columns: [
            { width: 8, displayName: 'tied to kanzi studio license', indexName: 'kc_tied_to' },
            { width: 4, displayName: 'support & maintenance expires on', indexName: 'support_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    },
    {
        name: 'Floating Perpetual',
        productId: Product.KanziConnect,
        licenseTypeId: LicenseType.FloatingPerpetual,
        columns: [
            { width: 6, displayName: 'tied to kanzi studio license', indexName: 'kc_tied_to' },
            { width: 2, displayName: 'user count', indexName: 'user_count' },
            { width: 4, displayName: 'support & maintenance expires on', indexName: 'support_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    },
    {
        name: 'Dongle Perpetual',
        productId: Product.KanziConnect,
        licenseTypeId: LicenseType.Dongle,
        columns: [
            { width: 8, displayName: 'tied to kanzi studio license', indexName: 'kc_tied_to' },
            { width: 4, displayName: 'support & maintenance expires on', indexName: 'support_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    }
])

export const kanziPluginLicenseCategories: LicenseCategory[] = ([
    {
        name: 'Subscription',
        productId: Product.KanziPlugin,
        licenseTypeId: LicenseType.Subscription,
        columns: [
            { width: 4, displayName: 'kanzi plug-in', indexName: 'plugin_name' },
            { width: 4, displayName: 'terms of use', indexName: 'plugin_level' },
            { width: 4, displayName: 'expires on', indexName: 'valid_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    },
    {
        name: 'Perpetual',
        productId: Product.KanziPlugin,
        licenseTypeId: LicenseType.Perpetual,
        columns: [
            { width: 4, displayName: 'kanzi plug-in', indexName: 'plugin_name' },
            { width: 4, displayName: 'terms of use', indexName: 'plugin_level' },
            { width: 4, displayName: 'support & maintenance expires on', indexName: 'support_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    }
])

export const kanziPsLicenseCategories: LicenseCategory[] = ([
    {
        name: 'Perpetual',
        productId: Product.KanziPlatform,
        licenseTypeId: LicenseType.Perpetual,
        columns: [
            { width: 2, displayName: 'kanzi version', indexName: 'license_version' },
            { width: 3, displayName: 'hardware', indexName: 'hw_name' },
            { width: 3, displayName: 'operating system', indexName: 'os_name' },
            { width: 3, displayName: 'platform', indexName: 'filename' },
            { width: 3, displayName: 'support & maintenance expires on', indexName: 'support_end_date' },
            { width: 2, displayName: 'expires in', indexName: 'days_to_renew' }
        ],
        licenses: []
    }
])

export const allLicenseCategories: LicenseCategory[] = ([
    ...kanziOneLicenseCategories,
    ...kanziStudioLicenseCategories,
    ...kanziConnectLicenseCategories,
    ...kanziPluginLicenseCategories,
    ...kanziPsLicenseCategories
])
