import { useMemo, useState } from 'react'
import { Icon, Accordion, Table } from 'semantic-ui-react'
import FileCategory from 'interfaces/FileCategory'
import FileRow from './FileRow'
import { enumFromString } from 'utils'
import ProductFamily from 'interfaces/ProductFamily'

function getProductFamilyName(productFamily: ProductFamily | undefined) {
    switch (productFamily) {
        case ProductFamily.KanziStudio:
            return 'Kanzi Studio'
        case ProductFamily.KanziConnect:
            return 'Kanzi Connect'
        case ProductFamily.KanziEngine:
            return 'Kanzi Engine'
        case ProductFamily.KanziShapes:
            return 'Kanzi Shapes'
        case ProductFamily.KanziMaps:
            return 'Kanzi Maps'
        case ProductFamily.KanziParticles:
            return 'Kanzi Particles'
        case ProductFamily.KanziStereoscopy:
            return 'Kanzi Sterescopy'
        case ProductFamily.KanziVR:
            return 'Kanzi VR'
        case ProductFamily.KanziAssetPackages:
            return 'Kanzi Asset Packages'
        case ProductFamily.KanziPreviews:
            return 'Previews'
        case ProductFamily.KanziConnectPlatformPackage:
            return 'Kanzi Connect Platform Package'
        default:
            return 'Unknown'
    }
}

interface PropTypes {
    fileCategory: FileCategory
}

function ProductAccordionItem(props: PropTypes) {
    const [isActive, setIsActive] = useState(false)

    function handleClick() {
        setIsActive(!isActive)
    }

    const productFamily = enumFromString(ProductFamily, props.fileCategory.name)
    const accordionName = getProductFamilyName(productFamily)

    const content = useMemo(() => {
        const rows = props.fileCategory.files.map(file =>
            <FileRow key={file.file_id} file={file} />)

        return (
            <Table basic='very' compact>
                <Table.Header className='fine-print'>
                    <Table.Row>
                        <Table.HeaderCell width={1}></Table.HeaderCell>
                        <Table.HeaderCell width={13}>Filename</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Download</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{rows}</Table.Body>
            </Table>
        )
    }, [props.fileCategory])

    return (
        <div key={props.fileCategory.name} data-cy='product-accordion-item'>
            <Accordion.Title active={isActive} onClick={handleClick}>
                <Icon name='dropdown' />
                {accordionName}
            </Accordion.Title>
            <Accordion.Content active={isActive}>
                {content}
            </Accordion.Content>
        </div>
    )
}

export default ProductAccordionItem
