import api from 'api/index'
import { useState, useEffect } from 'react'
import Session from 'models/Session'
import PortalRole from 'models/PortalRole'
import BranchRole from 'models/BranchRole'
import { enumFromString } from 'utils'

/** Get the user's session. */
function useSession() {
    const [session, setSession] = useState(new Session())
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const getSession = async () => {
            try {
                setIsLoading(true)
                const response = await api.getSession()
                const userId = response.data.userId
                const portalRole = enumFromString(PortalRole, response.data.portalRole)
                const branchRole = enumFromString(BranchRole, response.data.branchRole)
                const email = response.data.email
                const branchId = response.data.branchId
                const session = new Session(userId, portalRole, branchRole, email, branchId)
                setSession(session)
            }
            catch (e) {
                setSession({ ...session, portalRole: PortalRole.Unauthenticated })
            }
            finally {
                setIsLoading(false)
            }
        }

        getSession()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return [session, setSession, isLoading] as const
}

export default useSession
