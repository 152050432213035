import LicenseCategory from 'interfaces/LicenseCategory'
import { Header } from 'semantic-ui-react'
import LicenseTable from './LicenseTable'

interface PropTypes {
    name?: string,
    licenseCategories: LicenseCategory[]
}

function LicenseSection(props: PropTypes) {
    let licenseTables: JSX.Element[] = []
    props.licenseCategories.forEach((licenseCategory, key) => {
        if (licenseCategory.licenses.length > 0) {
            licenseTables.push((<LicenseTable key={key} licenseCategory={licenseCategory} />))
        }
    })

    return (
        <>
            {props.name && <>
                <Header dividing as='h2'>{props.name}</Header>
            </>}
            {licenseTables}
        </>
    )
}

export default LicenseSection
