import { useState } from 'react'
import { Modal, Button, Form, Divider } from 'semantic-ui-react'
import EmailField from './EmailField'
import api from 'api/index'
import BranchRole from 'models/BranchRole'
import Branch from 'interfaces/Branch'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import User from 'interfaces/User'
import BranchSection from './BranchSection'
import IBranchRole from 'interfaces/BranchRole'

interface FormState {
    email: string,
    branchRoles: IBranchRole[]
}

const defaultState: FormState = {
    email: '',
    branchRoles: []
}

interface PropTypes {
    branches: Branch[],
    onSuccess: (user: User) => void
}

function CreateUserModal(props: PropTypes) {
    const [formState, setFormState] = useState<FormState>(defaultState)
    const [isOpen, setIsOpen] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        try {
            e.preventDefault()
            setIsSubmitting(true)

            const response = await api.admin.createUser({
                userId: -1,
                email: formState.email,
                branchId: formState.branchRoles[0]?.branchId,
                branchRoles: formState.branchRoles
            })

            setFormState(defaultState)
            setIsOpen(false)
            props.onSuccess(response.data.user)
            toast.success(`Successfully created user with email ${formState.email}.`)
        }
        catch (e) {
            if (e instanceof AxiosError) {
                const erroMessage = e.response?.data.error?.message ?? e.message
                toast.error(`Failed to create user: ${erroMessage}.`)
            }
        }
        finally {
            setIsSubmitting(false)
        }
    }

    function isStateValid() {
        if (!formState.email) {
            return false
        }

        if (formState.branchRoles.some(branchRole =>
            branchRole.branchId < 0 || branchRole.role === BranchRole.None)) {
            return false
        }

        return true
    }

    return (
        <Modal
            as={Form}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onSubmit={handleSubmit}
            trigger={<Button basic circular size='small' icon='plus' title='Create a user.'/>}
            size='small'
            dimmer='inverted'
        >
            <Modal.Header>Create User</Modal.Header>
            <Modal.Content>
                <EmailField value={formState.email}
                    onChange={email => setFormState({ ...formState, email })} />
                <Divider hidden />
                {<BranchSection value={formState.branchRoles} branches={props.branches}
                    onChange={branchRoles => { setFormState({ ...formState, branchRoles })} }
                />}
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={isSubmitting} content='Cancel'
                    type='button' onClick={() => setIsOpen(false)}/>
                <Button disabled={isSubmitting || !isStateValid()} loading={isSubmitting}
                    type='submit' content='Ok' primary />
            </Modal.Actions>
        </Modal>
    )
}

export default CreateUserModal
