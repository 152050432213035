import { Card } from 'semantic-ui-react'

interface PropTypes {
    releaseNotesUrl: string
}

function WhatsNewCard(props: PropTypes) {
    return (
        <Card fluid>
            <Card.Content header='What&apos;s New'/>
            <Card.Content>
                <p>See the
                    <a href={props.releaseNotesUrl} target="_blank"
                        rel="noreferrer"> release notes </a>
                    to find out what&apos;s new in the latest version of Kanzi.
                </p>
            </Card.Content>
        </Card>
    )
}

export default WhatsNewCard
