import axios, { RawAxiosRequestConfig } from 'axios'

const auth = {
    async login(email: string, password: string) {
        return await axios.post('/login', { email, password })
    },
    async logout() {
        return await axios.post('/logout')
    },
    async getSession(config?: RawAxiosRequestConfig) {
        return await axios.get('/session', config)
    },
    async updateSession(branchId: number) {
        return await axios.patch('/session', { branchId })
    }
}

export default auth
