/** Enum representing products in the 'products' database table. */
enum Product {
    KanziStudio = '1',
    KanziPlatform = '2',
    KPA = '3',
    KPAPlatform = '4',
    KPAPro = '5',
    KanziLitePlatform = '6',
    KanziPlugin = '7',
    KanziDemo = '8',
    KanziConnect = '9',
    KanziDualRenderer = '10',
    ReferenceHMI = '11',
    SourceCodeAccess = '12',
    KanziOne = '13'
}

export default Product
