import './FileAccordion.css'
import { Accordion } from 'semantic-ui-react'
import FileAccordionItem from './FileAccordionItem'
import FileCategory from 'interfaces/FileCategory'

interface PropTypes {
    fileCategories: FileCategory[]
}

function FileAccordion(props: PropTypes) {
    const items = props.fileCategories.map(fileCategory => {
        return <FileAccordionItem key={fileCategory.name} fileCategory={fileCategory}/>
    })

    return (
        <Accordion className='file-accordion'>
            {items}
        </Accordion>
    )
}

export default FileAccordion
