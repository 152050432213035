import { Button, Table } from 'semantic-ui-react'
import api from 'api/index'
import File from 'interfaces/File'
import CreateLinkCheckbox from 'components/admin/download/CreateLinkCheckbox'

interface PropTypes {
    file: File
}

function FileTableRow(props: PropTypes) {
    async function downloadFile(file: File) {
        try {
            const response = await api.getDownloadLink(file.md5_hash)
            const downloadLink = response.data

            // Audit must be done before the download.
            // Otherwise on Firefox the audit request fails due to page refresh.
            await api.auditDownload(downloadLink)

            window.location.href = downloadLink
        }
        catch {
        }
    }

    return (
        <Table.Row data-testid='file-row'>
            <Table.Cell><CreateLinkCheckbox file={props.file}/></Table.Cell>
            <Table.Cell>{props.file.filename}</Table.Cell>
            <Table.Cell>
                <Button basic size='mini' color='black' as='a'
                    style={{ marginTop: '3px', marginRight: '3px' }}
                    onClick={(e) => downloadFile(props.file)}
                    data-cy='download-button'>
                    Download
                </Button>
            </Table.Cell>
        </Table.Row>
    )
}

export default FileTableRow
