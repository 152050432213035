import { useState, useEffect } from 'react'
import { Tab, Grid } from 'semantic-ui-react'
import OrganizationTable from './OrganizationTable'
import SearchField from 'components/shared/SearchField'
import CreateOrganizationModal from './modals/CreateOrganizationModal'
import Organization from 'interfaces/Organization'
import api from 'api'

function OrganizationTab() {
    const [organizations, setOrganizations] = useState<Organization[]>([])
    const [searchValue, setSearchValue] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        const loadData = async () => {
            try {
                setIsLoading(true)
                setErrorMessage('')

                const response = await api.admin.getOrganizations()
                const organizations: Organization[] = response.data.organizations
                organizations.sort((a, b) => a.name.localeCompare(b.name))
                setOrganizations(organizations)
            }
            catch (e) {
                if (e instanceof Error) {
                    setErrorMessage(`Failed to load organizations: ${e.message}`)
                }
            }
            finally {
                setIsLoading(false)
            }
        }

        loadData()
    }, [])

    return (
        <Tab.Pane>
            <Grid.Column width={13}>
                <Grid verticalAlign='middle' stackable>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <SearchField onChange={setSearchValue}/>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            <CreateOrganizationModal
                                onSuccess={organization =>
                                    setOrganizations([...organizations, organization])} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <OrganizationTable
                    organizations={organizations}
                    searchValue={searchValue}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                />
            </Grid.Column>
        </Tab.Pane>
    )
}

export default OrganizationTab
