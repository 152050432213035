import { useState } from 'react'
import { Checkbox } from 'semantic-ui-react'
import File from 'interfaces/File'

interface PropTypes {
    file: File
}

function CreateLinkCheckbox(props: PropTypes) {
    const [isChecked, setIsChecked] = useState(false)

    const handleClick = () => {
        props.file.isSelectedForDownload = !isChecked
        setIsChecked(!isChecked)
    }

    return (
        <Checkbox onChange={handleClick} checked={isChecked} data-cy='checkbox'/>
    )
}

export default CreateLinkCheckbox
