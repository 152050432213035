import Branch from 'interfaces/Branch'
import { Card, Table } from 'semantic-ui-react'

interface PropTypes {
    branch?: Branch,
}

function ContactCard(props: PropTypes) {
    return (
        <Card fluid>
            <Card.Content header='Contact'/>
            <Card.Content>
                <p>Please contact your Kanzi Sales Representative for any questions
                    regarding Kanzi.</p>
                <Table basic='very' fixed>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Name</Table.Cell>
                            <Table.Cell>{props.branch?.accountManagerName}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Email</Table.Cell>
                            <Table.Cell>
                                <a href={'mailto:' + props.branch?.accountManagerEmail}>{props.branch?.accountManagerEmail}</a>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Card.Content>
        </Card>
    )
}

export default ContactCard
